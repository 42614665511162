
        @import "./styles/matcha.scss";
      
.background {
  min-width: 100vw;
  height: 100vh;
  height: 100dvh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: auto;
}
